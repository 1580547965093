<template>
	<v-container fluid>
		<v-data-table
			:headers="headers"
			:footer-props="footer_props"
			:server-items-length="paginator.total"
			:items="leadList"
			id="leadsList"
			@click:row="handleClick"
			@update:page="pageChange"
			class="pointer"
		>
			<template v-slot:[`item.projectUser.phoneVerified`]="{ item }">
				<p
					style="color: green"
					v-if="item.projectUser && item.projectUser.phoneVerified"
				>
					True
				</p>
				<p style="color: orange" v-else>False</p>
			</template>
			<template v-slot:[`item.projectUser.emailVerified`]="{ item }">
				<p
					style="color: green"
					v-if="item.projectUser && item.projectUser.emailVerified"
				>
					True
				</p>
				<p style="color: orange" v-else>False</p>
			</template>
		</v-data-table>

		<v-navigation-drawer
			v-if="drawer"
			v-model="drawer"
			temporary
			stateless
			fixed
			width="50%"
			right
			class="footer-drawer"
		>
			<div class="py-4">
				<div class="pb-1 mb-4 header">
					<div
						class="
							px-8
							d-flex
							flex-row
							align-start
							justify-space-between
						"
					>
						<h1 class="crud-title">Lead Details</h1>
						<span
							class="mdi mdi-close close-icon pointer"
							@click="drawer = !drawer"
						></span>
					</div>
				</div>
				<div class="sharing px-8">
					<div>
						<p class="heading mb-3">Name</p>

						<v-text-field
							class="pa-0"
							v-model="selectedLead.projectUser.name"
							outlined
							placeholder="Name"
							dense
						></v-text-field>
					</div>
					<div class="right">
						<p class="heading mb-3">Is Archieved</p>
						<v-switch
							style="margin: 0px"
							color="blue"
							v-model="selectedLead.isArchieve"
						></v-switch>
					</div>
				</div>
				<div class="sharing px-8">
					<div>
						<p class="heading mb-3">Email</p>

						<v-text-field
							v-model="selectedLead.projectUser.email"
							outlined
							placeholder="Email"
							dense
						></v-text-field>
						<v-switch
							style="margin: 0px"
							label="Is Verified"
							color="blue"
							v-model="selectedLead.projectUser.emailVerified"
						></v-switch>
					</div>

					<div class="right">
						<p class="heading mb-3">Phone Number</p>
						<v-text-field
							class="pa-0"
							v-model="selectedLead.projectUser.phoneNumber"
							outlined
							placeholder="Phone Number"
							dense
						></v-text-field>
						<v-switch
							style="margin: 0px"
							label="Is Verified"
							color="blue"
							v-model="selectedLead.projectUser.phoneVerified"
						></v-switch>
					</div>
				</div>

				<div class="px-8">
					<p class="heading mb-3">Message</p>
					<v-textarea
						disabled
						outlined
						v-model="selectedLead.message"
					></v-textarea>
				</div>
				<div class="sharing px-8">
					<div>
						<p c.lass="heading mb-3">Source</p>
						<v-chip>Website</v-chip>
					</div>
					<div class="link">
						<p c.lass="heading mb-3">Link</p>
						<v-text-field
							class="pa-0"
							v-model="selectedLead.url"
							disabled
							outlined
							placeholder="Link"
							dense
						></v-text-field>
					</div>
				</div>
				<div class="px-8"></div>
				<div v-if="selectedLead.tags.length > 0" class="px-8">
					<p class="heading mb-3">LeadTags</p>
					<v-chip
						v-for="(tag, index) in selectedLead.tags"
						:key="index"
						>{{ tag }}</v-chip
					>
				</div>
			</div>
		</v-navigation-drawer>
	</v-container>
</template>
<script>
export default {
	data() {
		return {
			footer_props: {
				'items-per-page-options': [],
				'items-per-page-text': null,
				'disable-items-per-page': true,
			},

			drawer: false,
		}
	},
	created() {
		this.$store.dispatch('lead/getListingData', 1)
	},
	methods: {
		pageChange(page) {
			this.$store.dispatch('lead/getListingData', page)
		},
		handleClick(val) {
			this.drawer = true
			this.$store.commit('lead/populateSelectedLead', val)
		},
	},
	computed: {
		headers() {
			return this.$store.getters['lead/getHeaders']
		},
		leadList() {
			return this.$store.getters['lead/getLeadData']
		},
		paginator() {
			return this.$store.getters['lead/getPaginator']
		},
		selectedLead() {
			return this.$store.getters['lead/getSelectedLead']
		},
	},
}
</script>

<style scoped>
.heading {
	color: #7a8994;
}
.sharing {
	display: flex;
	flex-shrink: 0;
}
.right {
	margin-left: 15%;
}
.link {
	margin-left: 15%;
	width: 100%;
}
.pointer {
	cursor: pointer;
}
</style>
